$primary-color: #0375A0 !default;

@import "../node_modules/materialize-css/sass/materialize.scss";
@import "components/parallax";

h1 {
  font-size: 3.7rem;
}

.card-panel {
  @media only dscreen and (max-width: 600px) {
    padding: 14px;
  }
}

.container {
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

nav {
  height: 7.5rem;

  .nav-wrapper {
    margin: auto;
  }

  @media (min-width: 1280px) {
    .nav-wrapper {
      max-width: 80%;
    }
  }

  ul {
    margin-top: 2rem;
  }
}

.parallax-container {
  h1 {
    padding-top: 1rem;
  }
}

nav ul a,
nav .brand-logo {
  color: #444;
}

//nav ul li {
//  line-height: 20px;
//  padding: 22px 0 22px 0;
//}

nav li.selected a {
  //border-bottom: .5rem solid #0375A0;
  font-weight: bold;
  font-size: 1.1rem;
}

main {
  min-height: calc(100vh - 160px);
}

.brand-logo img {
  height: 5.5rem;
  margin: 1rem;
}

.wide-container {
  width: 80%;
}

img.full-width {
  width: 100% !important;
}

#divisions {
  width: 90%;
}

.bio img {
  width: 100%;
}

h1, h2, h3 {
  font-family: 'Merriweather';
}

.card-panel h3 {
  padding-top: 0;
  margin-top: 0;
}

p {
  line-height: 2rem;
}

.sidenav-trigger {
  color: #26a69a;

  .material-icons {
    margin-top: 1.6rem;
    font-size: 2.5rem;
  }
}

@media only screen and (max-width : 600px) {
  #index-banner .section {
    top: 0;
  }
}

.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
  font-size: inherit;
}

footer.page-footer {
  margin: 0;
  padding: 1rem;
}

@media only screen and (min-width: 949px) {
  footer.page-footer {
    padding: 2rem;
  }
}

.inline-icon {
  vertical-align: middle;
  font-size: 2rem !important;
}

.stacked-contact {
  li {
    line-height: 3rem;
  }
}

.contact-button {
  background-color: #F0B46C;
  color: #FFF;
  display: block;
  margin-bottom: 1rem;
}

//Pages

.page h2 {
  margin-bottom: 2rem;
  font-size: 2rem;
  padding-bottom: 1rem;
}

.page.home {
  h2 {
    padding-bottom: 0;
  }

  .hero-text {
    font-size: 1rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1rem;

    li {
      list-style-type: disc;
    }
  }

  @media only screen and (min-width : 550px) {
    h1 {
      font-size: 3.5rem;
    }

    .hero-text {
      font-size: 1.4rem;
    }
  }

  @media only screen and (min-width : 993px) {
    h1.header {
      font-size: 5rem;
      margin-bottom: 0;
      padding: 0;
    }

    h2 {
      margin-bottom: 0;
    }

    .hero-text {
      font-size: 1.7rem;
    }
  }

  @media only screen and (min-width : 1400px) {
    h1.header {
      padding: 1rem;
    }

    h2 {
      margin: 2rem;
    }
  }
}

.page .card-panel p:first-child {
  margin-top: 0;
}

.page.about .profile {
  img {
    height: 13rem;
  }
}

.heading-border {
  border-bottom-width: .75rem;
  border-bottom-style: solid;

  &--brown {
    border-bottom-color: #D6B2A2;
  }

  &--yellow {
    border-bottom-color: #F0B46C;
  }

  &--teal {
    border-bottom-color: #0375A0;
  }
}

.big-icon {
  text-align: center;

  i.material-icons {
    font-size: 5rem;
    color: #0375A0;
  }
}

.side-by {
  .left-side {
    display: table-cell;
    min-width: 160px;
    padding-right: 1rem;
  }
  .right-side {
    display: table-cell;
    width: 100%;
    vertical-align: top;
  }
}

.about {
  h4 {
    margin-top: 0;
  }
}

.post-excerpt {
  padding-bottom: 1rem;
  border-bottom: 1px solid #DDD;

  h6 {
    font-size: 1.3rem;
  }

  &:last-child{
    border: 0;
  }
}

.slider .slides li .caption {
  width: 80%;
  left: 10%;

  h1 {
    font-size: 3rem;
  }
  h3 {
    font-size: 1.3rem;
    line-height: 160%;
  }
}

.home {
  hr {
    margin-bottom: 2rem;
    border: 1px solid #F0B46C;
  }
}
