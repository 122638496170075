.parallax-container {
  height: 600px;

  &.dark-overlay {
    background: rgba(0, 0, 0, .6);
  }

  h1 {
    padding-top: 4rem;
    @media #{$medium-and-down} {
      font-size: 2.5rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-family: 'Lora';

    @media #{$medium-and-down} {
      font-size: 1.3rem;
    }
  }

  h5 {
    font-family: 'Lora';
  }
  .section {
    width: 100%;
  }

  ul li {
    font-size: 1.4rem;

    @media #{$medium-and-down} {
      font-size: 1.25rem;
    }

    strong {
      font-size: 2rem;
      @media #{$medium-and-down} {
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (max-width : 992px) {
  .parallax-container .section {
    position: absolute;
    top: 0;
  }
  #index-banner .section {
    top: 0;
  }
}
